import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };



  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
}, []);

useEffect(() => {
  fetch('/api/location')
    .then(response => response.json())
    .then(data => {
      const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
      setCountry(data.country);
      setRegion(regionNames.of(data.country.toUpperCase()));
      setCity(data.city);
      setCity(decodedCity);
    })
    .catch(error => console.error('Fetch error:', error));
}, []);


  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };


  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container">


    <a class="header-logo" href="https://onlyfans.com/milana_rose/c20">
     <div class="onlyfans"></div>
    </a>
      <img 
        src="https://i.ibb.co/Cwkcq77/photo-2024-07-16-22-03-30-modified.png" 
        alt="Header Image"
        className="header-image"
      />

<div className='textContainer'>
        <section>
            <div className='username'><strong>Milana Rose</strong></div>
            <div className='useraccountname'>@milana_rose</div>

            <div id="user-status" class="user-status">
            <div class="status"></div>
            <div id="available">Available now</div>
            <div class="separator"></div>
            <div class="clock"></div>  
            <div class="respond-time">Responds in 2 minutes</div>
          </div>

        </section>
      </div>

      <div class="textContainer">
      <div class="location"></div>
        <span id="location-name">{city ? `${city} ${region}` : '<3'}</span>
      </div>

      <div class="textContainer2">
      <div class="time-in-current-location">
        I’m staying in {city || 'your city'} from {formatDateString(fiveDaysBefore)} to {formatDateString(oneDayAfter)} 🥰<br/>
        Matches only: send me a ❤️ in my DMs
      </div>
      </div>


      <div class="textContainer3">
      <a href="https://onlyfans.com/milana_rose/c20" id="customButton">
      <div class="onlyfans"></div>
       My NSFW Onlyfans page 🔞
      </a>
      </div>




      <div class="countdown-block">
      <span class="discount"><strong>70% OFF </strong></span>
      <span class="timer-end">ends in </span>
      <span id="timer">{formatTimeLeft(timeLeft)}</span>
    </div>
    

    
      <Analytics/>
    </div>
      } />
      <Route path="/I-Am-Waiting-For-You/onlyfans.com/milana_rose" element={<G />} />
      <Route path="/onlyfans.com/milana_rose" element={<G />} />
      </Routes>
      </Router>





  );
}

export default App;
